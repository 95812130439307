import { BaseIntersectionController } from '../utils/base_intersection_controller';

const shadowClasses = ['shadow-xl', 'shadow-black'];

export default class extends BaseIntersectionController {
  observerFn(entry: IntersectionObserverEntry) {
    const header = entry.target?.previousElementSibling;
    if (!header) return;

    if (entry.isIntersecting) {
      header.classList.remove(...shadowClasses);
    } else if (!header.classList.contains(shadowClasses[0])) {
      header.classList.add(...shadowClasses);
    }
  }

  elementTargetConnected(element: HTMLElement) {
    const elementToWatch = element.nextElementSibling;
    if (elementToWatch) {
      this.observer?.observe(elementToWatch);
    }
  }

  elementTargetDisconnected(element: HTMLElement) {
    const elementToWatch = element.nextElementSibling;
    if (elementToWatch) {
      this.observer?.unobserve(elementToWatch);
    }
  }

  get intersectionObserverOptions(): IntersectionObserverInit {
    return { threshold: 1, rootMargin: '-45px 0px 0px 0px' };
  }
}
